.charts-grid {
  padding: 12px;
  -webkit-user-select: none;
  user-select: none;
}

.charts-grid .MuiCard-root {
  margin: 6px;
}

.chart-content {
  width: 100%;
  height: 100%;
  min-height: 325px;
  max-height: 350px;
  touch-action: pan-y !important;
}

.chart-content canvas {
  touch-action: pan-y !important;
}

@media screen and (max-width: 959px) {
  .charts-grid {
    padding: 0;
  }
  
  .charts-grid .MuiCard-root {
    margin: 0 0 6px;
  }

  .chart-content {
    min-height: 260px;
    max-height: 500px;
  }
}

.annotation-tooltip {
  position: fixed;
  background-color: white;
  border-radius: 4px;
  box-shadow: 2px 2px rgba(0,0,0,0.2);
  padding: 6px;
  z-index: 1000;
  max-width: 400px;
}