.admin-container {
  display: flex;
  position: absolute;
  top: 65px;
  height: calc(100vh - 65px);
  right: 0;
  left: 72px;
}

.admin-container .admin-nav {
  flex-shrink: 0;
  width: 250px;
  padding-left: 20px;
}

.admin-container .admin-content {
  flex-grow: 1;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: visible;
  padding: 20px 30px 20px 30px;
}

.admin-container .admin-nav-btn {
  width: 100%;
  padding: 10px;
  border: none;
  background: none;
  border-radius: 4px;
  text-align: left;
  font-size: 16px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.admin-container .admin-nav-btn:hover {
  background-color: rgba(0,0,0,0.05);
}

.admin-container .admin-nav-btn.active {
  background-color: white;
  color: rgb(0, 146, 135);
  box-shadow: 0 0 3px rgba(0,0,0,0.05);
}